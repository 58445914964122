import React from 'react';
import { Typography } from '@mui/material';
import { CustomModal } from './CustomModal';

interface Props {
    isOpen: boolean;
    title: string;
    body: string;
}

export const InformativeModal = ({ isOpen, title, body }: Props) => {
    return (
        <CustomModal isOpen={isOpen}>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {title}
            </Typography>
            <Typography
                lineHeight="26px"
                align="center"
                fontWeight="normal"
                variant="caption"
                component="h2"
                sx={{ whiteSpace: 'pre-line', color: '#5A6A7F', fontSize: '20px' }}
            >
                {body}
            </Typography>
        </CustomModal>
    );
};
