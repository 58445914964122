import swal from "sweetalert";

import strings  from '../config/strings';
import { HTTPError } from 'superagent';
import { clearCookie } from '../services/cookieService';
const { exceptionAlerts } = strings.UI;

export const alertError = (err: HTTPError) => {
    const code = err.status;
    if (code === 504){
        setTimeout(() => window.location.reload(), 3000);
    } else if (code === 403){
        return alertExpiredAuthError();
    } else if (code === undefined || code >= 500){
        return alertServerError();
    } else {
        window.location.href = '/';
    }
}


export const alertServerError = () => {
    swal(exceptionAlerts.serverError.title, exceptionAlerts.serverError.description, "warning")
    .then(() => {
        window.location.reload();
    })
}

export const alertExpiredAuthError = () => {
    swal(exceptionAlerts.tokenExpiredError.title, exceptionAlerts.tokenExpiredError.description, "warning")
    .then(() => {
        clearCookie('pin');
        localStorage.removeItem("private_token");
        window.location.href = '/';
    })
}


