import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { PinInputProps } from '../types';


export const PinInput = ({ setValues, isModalDisabled, values, label, errorText, autofocus }: PinInputProps) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setValues({ ...values, pin: e.target.value });
        }
    };

    const handleClickShowPin = () => {
        setValues({
            ...values,
            showPin: !values.showPin,
        });
    };

    const handleMouseDownPin = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <TextField
                disabled={isModalDisabled}
                variant="filled"
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '^[0-9]*$',
                    maxLength: 4,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle pin visibility"
                                onClick={handleClickShowPin}
                                onMouseDown={handleMouseDownPin}
                                edge="end"
                            >
                                {values.showPin ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                type={values.showPin ? 'text' : 'password'}
                value={values.pin}
                onChange={onChange}
                label={label}
                error={errorText.length > 0}
                helperText={errorText}
                autoFocus={ autofocus ? true : false }
            />
        </FormControl>
    );
};
