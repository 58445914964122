import React from 'react';
import { CustomModal } from './CustomModal';
import { Loading } from '../Loading';

/* const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
    };
}); */

export const LoadingModal = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <CustomModal isOpen={isOpen}>
            <Loading />
        </CustomModal>
    );
};
