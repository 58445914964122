import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (key: string) => {
    return cookies.get(key);
}

export const setSessionCookie = (key: string, value: string) => {
    return cookies.set(key, value);
}

export const clearCookie = (key: string) => {
    cookies.remove(key);
}
