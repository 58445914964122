import React from 'react';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CombinedReducersType } from '../store/modalTypes';

import strings  from '../config/strings';
const { layout : { infoMessage } } = strings.UI;

interface Props {
    setEffectiveMessage: React.Dispatch<React.SetStateAction<boolean>>;
    readOnlyDate: string | null;
}

const useStyles = makeStyles(() => {   
    return {
        container: {
            fontSize: '0.85rem',
            display: 'flex',
            position: 'absolute',
            marginTop: '-23px',
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            gap:'12px',
            border: '#2A87C9 1px solid',
            borderRadius: '6px',
            backgroundSize: 'cover',
            width: 'auto',
            overflow: 'auto',
            padding: '9px 16px',
            justifyContent: 'space-around'
        },
        icon: {
            color: '#2A87C9'
        },
        text: {
            color: '#2A87C9',
        }

    };
});

const getDate = function(date : Date){
    return ("0" + date.getUTCDate()).slice(-2);
}

const getMonth = function(date : Date){
    return ("0" + (date.getUTCMonth() + 1)).slice(-2);
}

export const EffectiveMessage = ({ setEffectiveMessage, readOnlyDate } : Props) => {
    const isNotReadOnly = useSelector((state : CombinedReducersType) => !state.authReducer.readOnly);
    const classes = useStyles();
    let text : string;
    if (isNotReadOnly){
        if (!readOnlyDate){
            return (<div style={{display: 'none'}}></div>);
        }
        const date = new Date(readOnlyDate);
        text = infoMessage.effectiveDate.replaceAll("{date}",`${getMonth(date)}/${getDate(date)}/${date.getUTCFullYear()}`);
    } else {
        text = infoMessage.readOnly;
    }

    const handleClose = () => {
        setEffectiveMessage(false);
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.wrapper}>
                <InfoIcon className={classes.icon} sx={{fontSize: '1.3rem'}}/>
                <Typography className={classes.text} sx={{fontSize: 'inherit'}}>
                    {text}
                </Typography>
                <CloseIcon className={classes.icon} sx={{cursor: 'pointer'}} fontSize='small' onClick={handleClose}/>
            </Box>
        </Box>
    );
};
