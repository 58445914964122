import { Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import strings  from '../config/strings';
const { page404 } = strings.UI;


export const NoMatch = () => {
    return (
        <Container>
            <Typography align="center" variant="h2">
                {page404.title}
            </Typography>
            <Typography variant="body1" marginTop="20px">
                <Link to="/">{page404.goHome}</Link>
            </Typography>
        </Container>
    );
};
