import superagent from 'superagent';

export const getInventoryStatus = async(token: string|null) => {
    console.log('[DEBUG] InventoryService getInventoryStatus');
    try{
        return superagent
            .get(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/status')
            .set('Authorization', `Bearer ` + token)
    }catch (err){
        console.error('[ERROR] InventoryService getInventoryStatus: ', err);
    }
}

export const getInventory = async (token: string|null) => {
    console.log('[DEBUG] InventoryService getInventory');
    try{
        return superagent
            .get(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory')
            .set('Authorization', `Bearer ` + token)
    }catch (err){
        console.error('[ERROR] InventoryService getInventory: ', err);
    }
}

export const getCategories = async (token: string|null) => {
    console.log('[DEBUG] InventoryService getCategories');
    try{
        return superagent
            .get(window.appConfig.REACT_APP_API_URL + '/customer-portal/apps/inventory')
            .set('Authorization', `Bearer ` + token)
    }catch (err){
        console.error('[ERROR] InventoryService getCategories: ', err);
    }
}

export const deleteInventory = async (token: string|null) => {
    console.log('[DEBUG] InventoryService deleteInventory');
    try{
        return superagent
            .delete(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory')
            .set('Authorization', `Bearer ` + token)
    }catch (err){
        console.error('[ERROR] InventoryService deleteInventory: ', err);
    }
}

type itemType = {
        name: string
        valuePerItem: number
        quantity: number
        weightPounds: number
        description: string
        cubicFeet: number
        category: string 
};

export const postInventory = async (token: string, items: itemType[]) => {
    console.log('[DEBUG] InventoryService postInventory');
    try{
        return superagent
            .post(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory')
            .set('Authorization', `Bearer ` + token)
            .send({items: items})
    }catch (err){
        console.error('[ERROR] InventoryService postInventory: ', err);
    }
}

export const putInventory = async (token: string, items: itemType[]) => {
    console.log('[DEBUG] InventoryService putInventory');
    try{
        return superagent
            .put(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory')
            .set('Authorization', `Bearer ` + token)
            .send({items: items})
    }catch (err){
        console.error('[ERROR] InventoryService putInventory: ', err);
    }
}

