import superagent from 'superagent';
import { Item, NewItem } from '../types';
import { alertError } from '../utils/alert';
import { HTTPError } from 'superagent';

export const getItem = async (token: string|null, itemId: string) => {
    console.log('[DEBUG] ItemService getItem');
    try{
        return superagent
            .get(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/items/' + itemId)
            .set('Authorization', `Bearer ` + token)
    }catch (err){
        console.error('[ERROR] ItemService getItem: ', err);
        alertError(err as HTTPError);
    }
}

export const deleteItem = async (token: string|null, itemId: string) => {
    console.log('[DEBUG] ItemService deleteItem');
    try{
        const res = await superagent
            .delete(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/items/' + itemId)
            .set('Authorization', `Bearer ` + token)
        return res;
    }catch (err){
        console.error('[ERROR] ItemService deleteItem: ', err);
        alertError(err as HTTPError);
    }
}

export const patchItem = async (token: string|null, newItem: Item) => {
    const {id, ...itemPatched} = newItem;
    console.log('[DEBUG] ItemService patchItem');
    try{
        const res = await superagent
        //Since we are changing the entire item, it's done via PUT
            .put(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/items/' + id)
            .set('Authorization', `Bearer ` + token)
            .send(itemPatched);
        return res;
    }catch (err){
        console.error('[ERROR] ItemService patchItem: ', err);
        alertError(err as HTTPError);
    }
}

export const postItems = async (token: string|null, items: NewItem) => {
    console.log('[DEBUG] ItemService postItems');
    try{
        const res = await superagent
            .post(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/items')
            .set('Authorization', `Bearer ` + token)
            .send(items);
        return res;
    }catch (err){
        console.error('[ERROR] ItemService postItems: ', err);
        alertError(err as HTTPError);
    }
}



