export const SET_PIN = "SET_PIN";
export const SET_PROGRAM_NAME = "SET_PROGRAM_NAME";
export const SET_DECLARATION_TYPE = "SET_DECLARATION_TYPE";
export const SET_READ_ONLY = "SET_READ_ONLY";

export interface AuthStatusType{
    pin: string|null;
    programName: string|null;
    declarationType: string|null;
    readOnly: boolean
};