import superagent from 'superagent';
import { alertError } from '../utils/alert';
import { HTTPError } from 'superagent';

export const getToken = async (publicToken: string|null, pin: string|null) => {
    console.log('[DEBUG] AuthService getToken');
    try{
        console.log('DEBUG', window.appConfig.REACT_APP_API_URL);
        const res = await superagent
        .post(window.appConfig.REACT_APP_API_URL + '/customer-portal/auth/public-token')
        .send({token: publicToken, pin: pin});
        return res;
    }catch (err){
        console.error('[ERROR] AuthService getToken: ', err);
        const httpError = err as HTTPError;
        if (httpError.status === 401 || httpError.status === 403) throw err;
        alertError(httpError);
    }
}

export const modifyPin = async (token: string,oldPin: string|null ,pin: string|null) => {
    console.log('[DEBUG] AuthService modifyPin');
    try{
        const res = await superagent
            .put(window.appConfig.REACT_APP_API_URL + '/customer-portal/auth/pin')
            .set('Authorization', `Bearer ` + token)
            .send({oldPin: oldPin, pin: pin})
        return res;
    }catch (err){
        console.error('[ERROR] AuthService modifyPin: ', err);
        alertError(err as HTTPError);
    }
}

export const resetPin = async (token: string|null) =>{
    console.log('[DEBUG] AuthService reset');
    try{
        const res = await superagent
            .get(window.appConfig.REACT_APP_API_URL + '/customer-portal/auth/pin/reset')
            .set('Authorization', `Bearer ` + token)
        return res;
    }catch (err){
        console.error('[ERROR] AuthService modifyPin: ', err);
        alertError(err as HTTPError);
    }
}

export const modifyDeclarationType = async (token: string|null , declarationType: string|undefined) => {
    console.log('[DEBUG] AuthService modifyDeclarationType');
    try{
        const res = await superagent
            .put(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/coverage')
            .set('Authorization', `Bearer ` + token)
            .send({
                programData: {
                    types: {
                        declaration: declarationType,
                    }
                }
            })
        return res;
    }catch (err){
        console.error('[ERROR] AuthService modifyDeclarationType: ', err);
        alertError(err as HTTPError);
    }
}

export const updateInventoryCompleted = async (token: string|null , completedInventory: boolean) => {
    console.log('[DEBUG] AuthService updateInventoryCompleted');
    try{
        const res = await superagent
            .put(window.appConfig.REACT_APP_API_URL + '/customer-portal/inventory/coverage')
            .set('Authorization', `Bearer ` + token)
            .send({
                completedInventory
            })
        return res;
    }catch (err){
        console.error('[ERROR] AuthService updateInventoryCompleted: ', err);
        alertError(err as HTTPError);
    }
}