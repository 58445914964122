import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../RIG_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { openChangePinModal, openAddPinModal, openDeclarationModal } from '../store/modalActions';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { CombinedReducersType } from '../store/modalTypes';

import { Coverage } from '../types';
import strings  from '../config/strings';
const { layout: { leftPane } } = strings.UI;


interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    coverage: Coverage | null;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
    return {
        page: {
            background: '#f9f9f9',
            width: '100%',
            padding: theme.spacing(3),
            marginTop: '64px',
            display: 'grid',
            placeContent: 'center',
            justifyContent: 'stretch',
        },
        appbar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawer: {
            width: drawerWidth,
            zIndex: 1,
        },
        drawerPaper: {
            width: drawerWidth,
            paddingTop: 64, // equal to AppBar height
        },
        root: {
            display: 'flex',
            minHeight: '100vh',
            zIndex: 1,
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.default,
        },
        title: {
            padding: theme.spacing(2),
        },
        toolbar: theme.mixins.toolbar,
        bottomPush: {
            position: 'fixed',
            bottom: 0,
            textAlign: 'center',
            width: 'inherit',
        },
        list: {
            position: 'fixed',
            textAlign: 'center',
            width: 'inherit',
        },
        logo: {
            maxWidth: 120,
            paddingLeft: '10px',
        },
    };
});

export const Layout: React.FC<Props> = ({ coverage }) => {
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const dispatch = useDispatch();

    const { authReducer } = useSelector((state: CombinedReducersType) => state);
    const { programName, readOnly, pin } = authReducer;

    return (
        <div className={classes.root}>
            {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

            <AppBar className={classes.appbar} color="secondary">
                <Toolbar>
                    <IconButton
                        onClick={handleDrawerToggle}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'block', sm: 'none' },
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={Logo} className={classes.logo} />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                }}
            >
                <List>
                    {programName === 'Moving Insurance' && (
                        <ListItem
                            disabled={readOnly}
                            button
                            key={leftPane.declarationType}
                            onClick={() => dispatch(openDeclarationModal())}
                        >
                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                                <AssignmentIcon htmlColor="#FBFBFB" />
                            </ListItemIcon>
                            <ListItemText primary={leftPane.declarationType} />
                        </ListItem>
                    )}
                    {pin != null ? (
                        <ListItem button key={leftPane.changePin} onClick={() => dispatch(openChangePinModal())}>
                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                                <LockIcon htmlColor="#FBFBFB" />
                            </ListItemIcon>
                            <ListItemText primary={leftPane.changePin} />
                        </ListItem>
                    ) : (
                        <ListItem button key={leftPane.addPin} onClick={() => dispatch(openAddPinModal())}>
                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                                <LockIcon htmlColor="#FBFBFB" />
                            </ListItemIcon>
                            <ListItemText primary={leftPane.addPin} />
                        </ListItem>
                    )}
                    {<List sx={{ display: !coverage ? 'none' : '', paddingLeft: '15px', paddingRight: '15px' }}>
                        <ListItemText
                            primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                            primary={leftPane.name}
                        />
                        <ListItemText
                            sx={{ paddingLeft: '7px' }}
                            primary={
                                coverage?.shipperName?.length && coverage?.shipperName?.length > 25
                                    ? coverage?.shipperName.substring(0, 25)
                                    : coverage?.shipperName
                            }
                        />
                        <ListItemText
                            primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                            primary={leftPane.coverageLimit}
                        />
                        <ListItemText
                            sx={{ paddingLeft: '7px' }}
                            primary={leftPane.currency.replaceAll('{value}', (String(coverage?.coverageLimit.toLocaleString('en-US'))))}
                        />
                        <ListItemText
                            primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                            primary={leftPane.declarationType + ":"}
                        />
                        <ListItemText
                            sx={{ paddingLeft: '7px' }}
                            primary={coverage?.program?.types.declaration}
                        />

                        <ListItemText
                            primaryTypographyProps={{ style: { fontSize: '15px' } }}
                            sx={{ marginTop: '350px', textAlign: 'center' }}
                            primary={leftPane.helpMessagge.replaceAll('{coverageId}', (coverage?.friendlyId || ''))}
                        />

                    </List>}
                </List>
                <div className={classes.bottomPush}>
                    <Typography variant="body2" padding="30px">
                        {leftPane.bottomCopy}
                    </Typography>
                </div>
            </Drawer>
            <Drawer
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                <div className={classes.list}>
                    <List>
                        {programName === 'Moving Insurance' && (
                            <ListItem
                                disabled={readOnly}
                                button
                                key={leftPane.declarationType}
                                onClick={() => dispatch(openDeclarationModal())}
                            >
                                <ListItemIcon sx={{ justifyContent: 'center' }}>
                                    <AssignmentIcon htmlColor="#FBFBFB" />
                                </ListItemIcon>
                                <ListItemText primary={leftPane.declarationType} />
                            </ListItem>
                        )}
                        {pin != null ? (
                            <ListItem button key={leftPane.changePin} onClick={() => dispatch(openChangePinModal())}>
                                <ListItemIcon sx={{ justifyContent: 'center' }}>
                                    <LockIcon htmlColor="#FBFBFB" />
                                </ListItemIcon>
                                <ListItemText primary={leftPane.changePin} />
                            </ListItem>
                        ) : (
                            <ListItem button key={leftPane.addPin} onClick={() => dispatch(openAddPinModal())}>
                                <ListItemIcon sx={{ justifyContent: 'center' }}>
                                    <LockIcon htmlColor="#FBFBFB" />
                                </ListItemIcon>
                                <ListItemText primary={leftPane.addPin} />
                            </ListItem>
                        )}
                    </List>
                </div>
                <List sx={{
                    display: !coverage ? 'none' : '',
                    marginTop: '60px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    textAlign: 'left'
                }}>
                    <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                        primary={leftPane.name}
                    />
                    <ListItemText
                        sx={{ paddingLeft: '7px' }}
                        primary={
                            coverage?.shipperName?.length && coverage?.shipperName?.length > 25
                                ? coverage?.shipperName.substring(0, 25)
                                : coverage?.shipperName
                        }
                    />
                    <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                        primary={leftPane.coverageLimit}
                    />
                    <ListItemText
                        sx={{ paddingLeft: '7px' }}
                        primary={leftPane.currency.replaceAll('{value}', (String(coverage?.coverageLimit.toLocaleString('en-US'))))}
                    />
                    <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                        primary={leftPane.declarationType + ":"}
                    />
                    <ListItemText
                        sx={{ paddingLeft: '7px' }}
                        primary={coverage?.program?.types.declaration}
                    />

                    <ListItemText
                        primaryTypographyProps={{ style: { fontSize: '15px' } }}
                        sx={{ marginTop: '310px', textAlign: 'center' }}
                        primary={leftPane.helpMessagge.replaceAll('{coverageId}', (coverage?.friendlyId || ''))}
                    />
                </List>
                <div className={classes.bottomPush}>
                    <Typography variant="body2" padding="30px">
                        {leftPane.bottomCopy}
                    </Typography>
                </div>
            </Drawer>

            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <div className={classes.page}>
                <Outlet />
            </div>
        </div>
    );
};
