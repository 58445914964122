import { SET_DECLARATION_TYPE, SET_PIN, SET_PROGRAM_NAME, SET_READ_ONLY } from "./authTypes";

export const setPin = (pin: string) => ({
    type: SET_PIN,
    payload: pin,
});

export const setProgramName = (programName: string|undefined) => ({
    type: SET_PROGRAM_NAME,
    payload: programName,
});

export const setDeclarationType = (declarationType: string|undefined) => ({
    type: SET_DECLARATION_TYPE,
    payload: declarationType,
});

export const setReadOnly = (readOnly: boolean) => ({
    type: SET_READ_ONLY,
    payload: readOnly,
});