import React from 'react';
import { postItems } from '../../services/itemService';
import { AddItemProps } from '../../types';
import { ItemModal } from './ItemModal';



export const AddItemModal = ({ addItem, setAddItem, categories, initItem, coverage, inventory }: AddItemProps) => {
    return (
        <ItemModal 
            showModal={addItem} 
            setShowModal={setAddItem} 
            categories={categories} 
            initItem={initItem} 
            itemAction={postItems}
            coverage={coverage}
            inventory={inventory}
        />
    );
};
