import { AuthStatusType } from "./authTypes";

export const OPEN_DEC_MODAL = "OPEN_DEC_MODAL";
export const CLOSE_DEC_MODAL = "CLOSE_DEC_MODAL";
export const OPEN_CHPIN_MODAL = "OPEN_CHPIN_MODAL";
export const CLOSE_CHPIN_MODAL = "CLOSE_CHPIN_MODAL";
export const OPEN_ADDPIN_MODAL = "OPEN_ADDPIN_MODAL";
export const CLOSE_ADDPIN_MODAL = "CLOSE_ADDPIN_MODAL";
export const OPEN_INV_MODAL = "OPEN_INV_COMPLETED_MODAL";
export const CLOSE_INV_MODAL = "CLOSE_INV_COMPLETED_MODAL";

export interface ModalStatusType{
    isDeclarationModalOpen: boolean;
    isChangePinModalOpen: boolean;
    isAddPinModalOpen: boolean;
    isInventoryCompletedModalOpen: boolean;
};

export interface InventoryStatusType{
    inventoryStatus: boolean;
};

export interface CombinedReducersType{
    authReducer: AuthStatusType;
    modalReducer: ModalStatusType;
    inventoryReducer: InventoryStatusType;
};