import { Modal, ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => {
    return {
        backDrop: {
            backdropFilter: 'blur(4px)',
        },
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxWidth: '600px',
            backgroundColor: 'white',
            padding: '50px',
        },
    };
});

interface Props {
    isOpen: boolean;
    onClose?: ButtonProps['onClick'];
    children?: JSX.Element|JSX.Element[];
}

export const CustomModal = ({ isOpen, onClose, children }: Props) => {
    const classes = useStyles();
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                classes: {
                    root: classes.backDrop,
                },
            }}
        >
            <Box className={classes.box}>{children}</Box>
        </Modal>
    );
};
