import { 
    CLOSE_CHPIN_MODAL, CLOSE_ADDPIN_MODAL, CLOSE_DEC_MODAL, CLOSE_INV_MODAL,
    OPEN_CHPIN_MODAL, OPEN_ADDPIN_MODAL, OPEN_DEC_MODAL, OPEN_INV_MODAL
 } from "./modalTypes";

export const openDeclarationModal = () => ({
    type: OPEN_DEC_MODAL,
});

export const closeDeclarationModal = () => ({
    type: CLOSE_DEC_MODAL,
});

export const openChangePinModal = () => ({
    type: OPEN_CHPIN_MODAL,
});

export const closeChangePinModal = () => ({
    type: CLOSE_CHPIN_MODAL,
});

export const openAddPinModal = () => ({
    type: OPEN_ADDPIN_MODAL,
});

export const closeAddPinModal = () => ({
    type: CLOSE_ADDPIN_MODAL,
});

export const openInventoryCompletedModal = () => ({
    type: OPEN_INV_MODAL,
});

export const closeInventoryCompletedModal = () => ({
    type: CLOSE_INV_MODAL,
});
