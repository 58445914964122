import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { openAddPinModal } from '../../store/modalActions';
import { useDispatch } from 'react-redux';
import Lock from '../../Lock.svg';

import strings  from '../../config/strings';
const { modals : { welcome : { setPin: setPinStrings } } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
        img: {
            width: '100%',
        },
    };
});

export const OptionalPinModal = ({ isOpen }: { isOpen: boolean }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleCancel = () => navigate('/');
    
    return (
        <CustomModal isOpen={isOpen} onClose={handleCancel}>
            <Box display="flex" justifyContent="space-around">
                <Box
                    width="60px"
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    padding="10px"
                    borderRadius="50%"
                    bgcolor="#8EC1E3"
                    marginBottom="16px"
                >
                    <img src={Lock} className={classes.img} />
                </Box>
            </Box>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {setPinStrings.title}
            </Typography>
            <Typography
                lineHeight="26px"
                align="center"
                fontWeight="normal"
                variant="caption"
                component="h2"
                sx={{ whiteSpace: 'pre-line', color: '#5A6A7F', fontSize: '20px' }}
            >
                {setPinStrings.description}
            </Typography>
            <Typography marginTop="40px" align="right">
                <Button
                    sx={{ marginRight: '16px' }}
                    className={classes.button}
                    color="primary"
                    variant="text"
                    onClick={handleCancel}
                >
                    {setPinStrings.buttons.cancel}
                </Button>
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        dispatch(openAddPinModal());
                    }}
                >
                    {setPinStrings.buttons.confirm}
                </Button>
            </Typography>
        </CustomModal>
    );
};
