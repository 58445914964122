import { Button, FormControl, FormHelperText, Grid, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { CustomModal } from './CustomModal';
import { Category, NewItem, Item, Coverage, DeclarationType } from '../../types';
import { Box } from '@mui/system';
import { LoadingButton } from './LoadingButton';
import superagent from 'superagent';
import swal from 'sweetalert';

import strings  from '../../config/strings';
const { modals : { addEditItem : itemStrings } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        textField: {
            width: '100%',
        },
        select: {
            width: '100%',
        },
        button: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px 0 0 0'
        },
        div: {
            margin: '10px',
        },
    };
});

const emptyItem = {
    name: '',
    valuePerItem: undefined,
    quantity: undefined,
    weightPounds: '0',
    description: '',
    cubicFeet: '0',
    category: '',
    isArt: false
};

const errorsInitialState = {
    nameError: '',
    quantityError: '',
    categoryError: '',
    costError: '',
    descError: '',
    coverageLimit: '',
};


interface Props<T>{
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    categories: Category[];
    initItem: NewItem | null;
    itemAction: ((token: string | null, items: T) => Promise<superagent.Response | undefined>);
    convertNewToItem?: (newItem: NewItem) => Item;
    coverage: Coverage | null;
    inventory: Item[];
}


export const ItemModal = <T extends (Item | NewItem)>({ showModal, setShowModal, categories, initItem, itemAction, convertNewToItem, coverage, inventory }: Props<T>) => {
    const classes = useStyles();
    const [item, setItem] = useState<NewItem>(emptyItem);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errors, setErrors] = useState(errorsInitialState);
    const [showCategories, setShowCategories] = useState<Category[]>([]);

    const private_token = localStorage.getItem('private_token');

    let messages : { [key: string]: string};
    if (convertNewToItem){
        messages = {
            successfulDesc: itemStrings.successfulAlert.editDescription,
            title: itemStrings.editTitle
        }
    } else {
        messages = {
            successfulDesc: itemStrings.successfulAlert.addDescription,
            title: itemStrings.addtitle
        }
    }


    const validateForm = () => {
        let isValid = true;
        let newErrors = errorsInitialState;
        if (item.name.length == 0) {
            newErrors = { ...newErrors, nameError: 'This is a required field' };
            isValid = false;
        }
        if (!item.quantity) {
            newErrors = { ...newErrors, quantityError: 'This is a required field' };
            isValid = false;
        }
        if (item.category.length == 0) {
            newErrors = { ...newErrors, categoryError: 'This is a required field' };
            isValid = false;
        }
        if (!item.valuePerItem) {
            newErrors = { ...newErrors, costError: 'This is a required field' };
            isValid = false;
        }

        if(coverage?.program?.types?.declaration === DeclarationType.specificItems) {
            let totalValue = 0;
            inventory.forEach((item: Item) => totalValue += item.valuePerItem * item.quantity);

            if(item.valuePerItem && item.quantity) {
                totalValue += item.valuePerItem * item.quantity;
            }
            
            if(totalValue > coverage?.coverageLimit) {
                newErrors = { 
                    ...newErrors, 
                    coverageLimit: `This item cannot be ${ convertNewToItem ? 'modified' : 'added' }, since it would exceed your $${coverage?.coverageLimit.toLocaleString('en-US')} coverage limit. If you need to raise your coverage limit, please call +1 (908) 731-6944 or email help@relocationinsurance.com and reference ID# ${coverage?.friendlyId}`
                };
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleItemChange = (value: { [key: string]: string | number | boolean; } ) => {
        const mergedNewItem = {
            ...item,
            ...value,
        };
        setItem(mergedNewItem);
    };
    const handleSubmitItem = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (validateForm()) {
            setIsDisabled(true);
            itemAction(private_token, convertNewToItem ? convertNewToItem(item) as T : item as T)
            .then((r) => {
                    if(r){
                        setTimeout(() => window.location.reload(), 7500);
                        swal(itemStrings.successfulAlert.title, messages.successfulDesc, "success").then(() => {
                            window.location.reload();
                        })
                        setIsDisabled(false);
                    }
                })
                .catch((err : superagent.HTTPError | undefined) => {
                    console.log('[ERROR]', err?.message);
                });
        }
    };

    useEffect(() => {
        initItem ? setItem(initItem) : setItem(emptyItem);
        setErrors(errorsInitialState);
        
        const allowedCategories = categories.filter(cat => !cat.isDisabled);
        const selectedCategory = initItem && categories.find(cat => cat.id === initItem.category);
        setShowCategories(selectedCategory?.isDisabled ? categories : allowedCategories);

    }, [showModal]);

    const handleCancel = () => {
        setShowModal(false);
    }

    return (
        <CustomModal isOpen={showModal} onClose={handleCancel}>
            <form autoComplete='off'>
            <input autoComplete="false" name="hidden" type="text" style={{display: 'none'}}/>
            <Box>
                <Typography color="#003D7B" variant="h6" align="center" marginBottom="1rem">
                    {messages.title}
                </Typography>
                <Typography color="#ff0000" variant="body2" align="left" marginBottom="1rem">
                    {errors.coverageLimit || ''}
                </Typography>
                <Grid container display="flex">
                    <Grid item xs={12}>
                        <Box className={classes.div}>
                            <TextField
                                className={classes.textField}
                                type="text"
                                label={itemStrings.formLabels.name}
                                variant="filled"
                                onChange={(e) => handleItemChange({ name: e.target.value })}
                                value={item.name}
                                disabled={isDisabled}
                                error={errors.nameError ? true : false}
                                helperText={errors.nameError}
                                autoFocus
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box margin="10px">
                            <TextField
                                className={classes.textField}
                                type="text"
                                label={itemStrings.formLabels.description}
                                variant="filled"
                                onChange={(e) => handleItemChange({ description: e.target.value })}
                                value={item.description}
                                disabled={isDisabled}
                                inputProps={{ maxLength: 500 }}
                                multiline={true}
                                error={errors.descError ? true : false}
                                helperText={errors.descError}
                            />
                            <Typography
                                fontSize="12px"
                                fontWeight="400"
                                align="right"
                                color="#727F91"
                            >{`${item.description?.length}/500 characters`}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.div}>
                            <TextField
                                className={classes.textField}
                                type="number"
                                label={itemStrings.formLabels.quantity}
                                variant="filled"
                                onChange={(e) => handleItemChange({ quantity: parseInt(e.target.value) })}
                                value={item.quantity || ''}
                                disabled={isDisabled}
                                error={errors.quantityError ? true : false}
                                helperText={errors.quantityError}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.div}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    error={errors.categoryError ? true : false}
                                    className={classes.select}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={itemStrings.formLabels.category}
                                    variant="filled"
                                    onChange={(e) => handleItemChange({ category: e.target.value })}
                                    value={item.category}
                                    disabled={isDisabled}
                                >
                                    {showCategories.map((cat: Category) => (
                                            <MenuItem key={cat.id} value={cat.id}>
                                                {cat.shortName}
                                            </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>{errors.categoryError}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.div}>
                            <TextField
                                className={classes.textField}
                                type="number"
                                label={itemStrings.formLabels.replacementCost}
                                variant="filled"
                                onChange={(e) => handleItemChange({ valuePerItem: parseInt(e.target.value) })}
                                value={item.valuePerItem || ''}
                                disabled={isDisabled}
                                error={errors.costError ? true : false}
                                helperText={errors.costError}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" className={classes.div}>
                            <Typography 
                                fontWeight="400"
                                color="#727F91"
                                sx={{ 
                                    flex: { sm: '0 0 80%', xs: '0 0 100%' }, 
                                    whiteSpace: 'pre-line',
                                    display: { sm: 'block' },
                                    fontSize: {sm: '15px', xs: '12px'}
                                }}>
                                    {itemStrings.formLabels.isArtDescription}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => handleItemChange({ isArt: e.target.checked })}
                                        color="primary"
                                    />
                                }
                                label={itemStrings.formLabels.isArt}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Box className={classes.button}>
                    <Button
                        disabled={isDisabled}
                        sx={{ marginRight: '16px' }}
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={handleCancel}
                    >
                        {itemStrings.buttons.cancel}
                    </Button>
                    <LoadingButton variant="contained" color="primary" onClick={handleSubmitItem} loading={isDisabled} type="submit">
                    {itemStrings.buttons.confirm}
                    </LoadingButton>
                </Box>
            </Box>
            </form>
        </CustomModal>
    );
};
