import React, { useState } from 'react';
import { Button, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { closeDeclarationModal } from '../../store/modalActions';
import { CombinedReducersType } from '../../store/modalTypes';
import { setDeclarationType } from '../../store/authActions';
import { modifyDeclarationType } from '../../services/authService';

import strings  from '../../config/strings';
const { modals : { declarationType : declarationStr } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
    };
});

interface Props {
    isOpen: boolean;
    setShowEntireShipmentInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSpecificItemsInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeclarationTypeModal = ({
    isOpen,
    setShowEntireShipmentInfoModal,
    setShowSpecificItemsInfoModal,
}: Props) => {
    const classes = useStyles();
    const [newDeclarationType, setNewDeclarationType] = useState<string | undefined>(undefined);
    const dispatch = useDispatch();
    const { authReducer } = useSelector((state: CombinedReducersType) => state);
    const { declarationType } = authReducer;

    const handleSubmit = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(closeDeclarationModal());
        dispatch(setDeclarationType(newDeclarationType));
        localStorage.setItem('first_time', 'false');
        const token = localStorage.getItem('private_token');
        modifyDeclarationType(token, newDeclarationType).then((res) => {
            console.log('Declaration type modified: ', res);
        });
    };

    const handleClose = () => {
        dispatch(closeDeclarationModal());
    }

    const notFirstTime = localStorage.getItem('first_time') == 'false';

    return (
        <CustomModal isOpen={isOpen} onClose={notFirstTime ? handleClose : undefined}>
            <form>
            <Typography
                marginBottom="40px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {declarationStr.title}
            </Typography>
            <RadioGroup
                defaultValue={declarationType}
                value={newDeclarationType}
                name="radio-buttons-group"
                onChange={(e) => setNewDeclarationType(e.target.value)}
                sx={{ alignContent: 'center' }}
            >
                <Typography>
                    <FormControlLabel value="Entire Shipment" control={<Radio />} label={declarationStr.entireShipment} />
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            dispatch(closeDeclarationModal());
                            setShowEntireShipmentInfoModal(true);
                        }}
                    >
                        <InfoIcon color="primary" />
                    </IconButton>
                </Typography>
                <Typography marginTop="10px">
                    <FormControlLabel value="Specific Items" control={<Radio />} label={declarationStr.specificItems} />
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            dispatch(closeDeclarationModal());
                            setShowSpecificItemsInfoModal(true);
                        }}
                    >
                        <InfoIcon color="primary" />
                    </IconButton>
                </Typography>
            </RadioGroup>

            <Typography marginTop="40px" align="right">
                {notFirstTime && (
                    <Button
                        sx={{ marginRight: '16px' }}
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={handleClose}
                    >
                        {declarationStr.buttons.cancel}
                    </Button>
                )}

                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    type="submit"
                    autoFocus
                >
                    {declarationStr.buttons.confirm}
                </Button>
            </Typography>
            </form>
        </CustomModal>
    );
};
