import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
    text: string;
    textMaxWidth: string | number;
    boxSx: SxProps;
    textSx: SxProps;
    expandDescription: boolean;
    setExpandDescription: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExpandableText = ({ expandDescription, setExpandDescription, text, textMaxWidth, boxSx, textSx } : Props) => {
    const [showButton, setShowButton] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    
    const isEllipsisActive = (e : HTMLElement) => {
        return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
    }

    useEffect(() => {
        if (textRef.current && isEllipsisActive(textRef.current)){
            setShowButton(true);
        }
    }, [])


    return (
        <Box sx={{...boxSx,maxWidth:'100%'}}>
            <Typography
                noWrap={!expandDescription}
                sx={{
                    ...textSx,
                    maxWidth: expandDescription ? '100%' : textMaxWidth,
                }}
                color="text.secondary"
                ref={textRef}
            >
                {text}
            </Typography>
            { showButton ? 
                <Button
                    sx={{
                        display: { xs: 'inline-flex' },
                    }}
                    size="small"
                    onClick={() => setExpandDescription(!expandDescription)}
                >
                    {expandDescription ? 'show less' : 'read more'}
                </Button>
            : null }
        </Box>
    );
};
