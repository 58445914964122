import React, { useState } from 'react';
import { Card, CardContent, Grid, IconButton, Typography, Box, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { CombinedReducersType } from '../store/modalTypes';
import { DeleteItemModal } from './modals/DeleteItemModal';
import { ExpandableText } from './ExpandableText';
import { NewItem, Item } from '../types';
import strings  from '../config/strings';
const { layout : { inventory : { itemView } } } = strings.UI;

interface Props {
    index: number;
    item: Item;
    setShowEditItem: React.Dispatch<React.SetStateAction<boolean>>;
    setInitItem: React.Dispatch<React.SetStateAction<NewItem | null>>;
    itemListLength: number;
}

export const ItemCard = ({ index, item, setShowEditItem, setInitItem, itemListLength }: Props) => {
    // const [isDisabled, setIsDisabled] = useState(false);
    const [expandDescription, setExpandDescription] = useState(false);
    const isDisabled = false;
    const [openedDeleteModal, setOpenedDeleteModal] = useState(false);
    const { authReducer } = useSelector((state: CombinedReducersType) => state);
    const { readOnly } = authReducer;

    const handleEditItem = () => {
        const initItem = {
            id: item.id,
            name: item.name,
            valuePerItem: item.valuePerItem,
            quantity: item.quantity,
            weightPounds: item.weightPounds,
            description: item.description,
            cubicFeet: item.cubicFeet.toString(),
            category: item.category,
            isArt: item.isArt,
        };
        setInitItem(initItem);
        setShowEditItem(true);
    };

    const lastItem = itemListLength - 1 === index;

    if (openedDeleteModal){
        return (
            <DeleteItemModal
                isOpen={openedDeleteModal}
                setShowInfoModal={setOpenedDeleteModal}
                itemName={item.name}
                itemId={item.id}
            />
        )
    }

    return (
        <Card sx={{ minWidth: 275, marginBottom: lastItem ? '0' : '16px' }}>
            <CardContent
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', alignItems: expandDescription ? 'flex-start' : 'center', justifyContent: 'flex-start' }}
                >
                    <Grid item={true} sm={4} xs={12} alignItems="center">
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="black" gutterBottom>
                                {item.name}
                            </Typography>
                            <Chip
                                label={item.quantity}
                                sx={{
                                    display: { xs: 'inline-flex', sm: 'none' },
                                    marginLeft: '6px',
                                }}
                            />
                        </Box>
                        <ExpandableText
                            expandDescription={expandDescription}
                            setExpandDescription={setExpandDescription}
                            text={item.description}
                            textMaxWidth={'30vw'}
                            boxSx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            textSx={{ fontSize: 15, maxWidth: { xs: '100px', sm: '100%' } }}
                        />
                    </Grid>
                    <Grid item={true} sm={2} xs={12} sx={{ display: { xs: 'flex', sm: 'block' } }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }} color="black" gutterBottom>
                            {itemView.replacementCost}
                        </Typography>
                        <Typography
                            sx={{ mb: 1.5, fontSize: 15, marginLeft: { xs: '6px', sm: '0' } }}
                            color="text.secondary"
                        >
                            ${item.valuePerItem}
                        </Typography>
                    </Grid>
                    <Grid
                        item={true}
                        sm={2}
                        xs={12}
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                        }}
                    >
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }} color="black" gutterBottom>
                            {itemView.quantity}
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: 15 }} color="text.secondary">
                            {item.quantity}
                        </Typography>
                    </Grid>
                    <Grid
                        item={true}
                        sm={2}
                        xs={12}
                        sx={{
                            display: { xs: 'flex', sm: 'block' },
                            paddingTop: { xs: '0', sm: '16px' },
                            paddingLeft: '16px',
                        }}
                    >
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }} color="black" gutterBottom>
                         {itemView.total}
                        </Typography>
                        <Typography
                            sx={{ mb: 1.5, fontSize: 15, marginLeft: { xs: '6px', sm: '0' } }}
                            color="text.secondary"
                        >
                            ${item.valuePerItem * item.quantity}
                        </Typography>
                    </Grid>
                </Grid>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        position: 'absolute', 
                        right: '16px', 
                        top: expandDescription ? '32px' : { xs: '16px', sm: 'auto' } 
                    }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        disabled={isDisabled || readOnly}
                        onClick={() => {
                            handleEditItem();
                        }}
                    >
                        <EditIcon fontSize="small" htmlColor="#003D7B" color={isDisabled || readOnly ? 'disabled' : 'inherit'} />
                    </IconButton>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        disabled={isDisabled || readOnly}
                        onClick={() => {
                            setOpenedDeleteModal(true);
                        }}
                    >
                        <DeleteIcon fontSize="small" htmlColor="#003D7B" color={isDisabled || readOnly ? 'disabled' : 'inherit'} />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};
