import React from 'react';
import { patchItem } from '../../services/itemService';
import { ItemModal } from './ItemModal';
import { EditItemProps, NewItem, Item } from '../../types';



export const EditItemModal = ({ showEditItem, setShowEditItem, categories, initItem, coverage, inventory }: EditItemProps) => {
    return (
        <ItemModal 
            showModal={showEditItem} 
            setShowModal={setShowEditItem} 
            categories={categories} 
            initItem={initItem} 
            itemAction={patchItem}
            coverage={coverage}
            inventory={inventory}
            convertNewToItem={(newItem: NewItem) : Item => {
                return { 
                    ...newItem,
                    id: newItem.id || '',
                    cubicFeet: parseInt(newItem.cubicFeet),
                    quantity: newItem.quantity || 0,
                    valuePerItem: newItem.valuePerItem || 0,
                }
            }}
        />
    );
};
