import { modalReducer, modalInitialState } from './modalReducer';
import { authReducer, authInitialState } from './authReducer';
import { inventoryReducer, inventoryInitialState } from './inventoryReducer';
import { getCookie,clearCookie } from '../services/cookieService';
import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

const mainReducer = combineReducers({
    modalReducer,
    authReducer,
    inventoryReducer
});


/***    Enables redux devtools debug
 *      change compose for composeEnhancers 
 import { composeWithDevTools } from 'redux-devtools-extension';

//  export const composeEnhancers =
//     (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

 */

const public_token = window.location.search?.split('public_token=')[1]
public_token && public_token != window.localStorage.getItem('public_token') && clearCookie('pin');

const initialState = {         
    authReducer: {
        ...authInitialState,
        pin: getCookie('pin')
    },
    modalReducer: modalInitialState,
    inventoryReducer: inventoryInitialState
};


export default createStore(
    mainReducer,
    initialState as any,
    compose(applyMiddleware(thunk)),
);
