import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, useMediaQuery } from '@mui/material';
import { CustomModal } from './CustomModal';
import strings from '../../config/strings';
import { InsuranceTermsTemplate } from '../InsuranceTermsTemplate';
import { InsuranceModalProps } from '../../types';

export const InsuranceTermsModal = ({ isOpen, setShowInsuranceTermsModal }: InsuranceModalProps) => {
  const handleClose = () => {
    setShowInsuranceTermsModal(false)
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth >
        <DialogTitle sx={{ fontSize: isMobile ? '16px' : null }}>
          {strings.UI.modals.insuranceTerms.title}
        </DialogTitle>
        <DialogContent dividers sx={{ fontSize: isMobile ? '14px' : null }}>
          <div>
            <InsuranceTermsTemplate />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          {strings.UI.modals.insuranceTerms.buttons.close}
          </Button>
        </DialogActions>
      </Dialog>
    </CustomModal>
  );
};
