import React from 'react';
import { deleteInventory } from '../../services/inventoryService';
import { DeleteModal } from './DeleteModal';

import strings  from '../../config/strings';
const { modals : { delete : { allItems : deleteAllStr } } } = strings.UI;

interface Props {
    isOpen: boolean;
    setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
    quantity: number;
}


export const DeleteItemsModal = ({ isOpen, setShowInfoModal, quantity }: Props) => {
    const description = deleteAllStr.description.replaceAll("{quantity}", quantity.toString());
    const title = deleteAllStr.title;
    return (
        <DeleteModal
         isOpen={isOpen}
         showModal={setShowInfoModal}
         deleteDescription={description}
         deleteTitle={title}
         deleteAction={() => deleteInventory(localStorage.getItem('private_token'))}
        />
    )
}
