import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { setPin } from '../../store/authActions';
import { useDispatch } from 'react-redux';
import Lock from '../../Lock.svg';
import { PinInput } from '../PinInput';
import { getToken, resetPin } from '../../services/authService';
import { setSessionCookie } from '../../services/cookieService';
import swal from 'sweetalert';

import strings  from '../../config/strings';
const { modals : { login : loginStrings, resetPin: resetPinStrings} } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
        img: {
            width: '100%',
        },
    };
});

const pinFieldInitialState = {
    pin: '',
    showPin: false,
};

const errorsInitialState = { newPinError: '' };

export const LoginModal = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newPin, setNewPin] = useState(pinFieldInitialState);
    const [resetPinDisabled, setResetPinDisabled] = useState(false);
    const [isModalDisabled, setIsModalDisabled] = useState(false);
    const [errors, setErrors] = useState(errorsInitialState);

    const validatePin = () => {
        let isValid = true;
        let newErrors = errorsInitialState;
        if (newPin.pin.length < 4) {
            newErrors = { ...newErrors, newPinError: 'Must contain 4 numbers' };
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleResetPINRequest = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const public_token = localStorage.getItem('public_token');
        resetPin(public_token);
        setResetPinDisabled(true)
        swal(resetPinStrings.title, resetPinStrings.description, "success")
    }

    const handlePinSubmit = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const public_token = localStorage.getItem('public_token');
        if (validatePin()) {
            setIsModalDisabled(true);
            getToken(public_token, newPin.pin)
                .then((res) => {
                    localStorage.setItem('private_token', res?.body.token);
                    dispatch(setPin(newPin.pin));
                    setSessionCookie('pin', newPin.pin);
                    navigate('/');
                })
                .catch((err) => {
                    if (err.response.statusCode == 401) {
                        let newErrors = errorsInitialState;
                        newErrors = { ...newErrors, newPinError: loginStrings.error };
                        setIsModalDisabled(false);
                        setErrors(newErrors);
                    } else {
                        console.log('[ERROR]', err);
                    }
                });
        }
    };

    return (
        <CustomModal isOpen={true}>
            <form>
            <Box display="flex" justifyContent="space-around">
                <Box
                    width="60px"
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    padding="10px"
                    borderRadius="50%"
                    bgcolor="#8EC1E3"
                    marginBottom="16px"
                >
                    <img src={Lock} className={classes.img} />
                </Box>
            </Box>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {loginStrings.title}
            </Typography>
            <Typography align="center" component={'div'}>
                <PinInput
                    setValues={setNewPin}
                    isModalDisabled={isModalDisabled}
                    values={newPin}
                    label={loginStrings.fields.pin}
                    errorText={errors.newPinError}
                    autofocus
                />
                <br />
                <Typography marginTop="1px" align="left" fontSize="12px" variant="caption" color="#5A6A7F">
                    {loginStrings.legend}
                </Typography>
            </Typography>
            <Typography marginTop="40px" align="right">
                {/* <Button
                    disabled={resetPinDisabled}
                    sx={{ marginRight: '16px' }}
                    className={classes.button}
                    color="primary"
                    variant="text"
                    onClick={handleResetPINRequest}
                >
                    {loginStrings.buttons.resetPIN}
                </Button> */}
                <Button
                    disabled={isModalDisabled}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handlePinSubmit}
                    type="submit"
                >
                    {loginStrings.buttons.confirm}
                </Button>
            </Typography>
            </form>
        </CustomModal>
    );
};
