import React from 'react';
import { DeleteModal } from './DeleteModal';
import { deleteItem } from '../../services/itemService';

import strings  from '../../config/strings';
const { modals : { delete : { singleItem : singleItemStr } } } = strings.UI;

interface Props {
    isOpen: boolean;
    setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
    itemName: string;
    itemId: string;
}


export const DeleteItemModal = ({ isOpen, setShowInfoModal, itemName, itemId }: Props) => {
    const title = singleItemStr.title.replaceAll("{name}", itemName);
    const body = singleItemStr.description.replaceAll("{name}", itemName);
    return (
        <DeleteModal
         isOpen={isOpen}
         showModal={setShowInfoModal}
         deleteDescription={body}
         deleteTitle={title}
         deleteAction={() => deleteItem(localStorage.getItem('private_token'), itemId)}
        />
    )
}
