export default {
    UI: {
        layout: {
            leftPane: {
                declarationType: "Declaration Type",
                changePin: "Change Pin",
                addPin: "Add Pin",
                bottomCopy: "Relocation Insurance Group, LLC.",
                name: "Name:",
                coverageLimit: "Coverage Limit:",
                helpMessagge: "For help, call +1 (908) 731-6944 or email help@relocationinsurance.com and reference ID# {coverageId}. Representatives are available Monday through Friday, 9:00 am to 6:00 pm EST.",
                currency: "USD $ {value}"
            }, 
            infoMessage: {
                readOnly: "Read Only Mode. You are viewing the app in read only mode, you cannot modify it",
                effectiveDate: "This inventory can be modified until: {date}"
            },
            inventory: {
                title: "Inventory List",
                buttons: {
                    deleteAll: "DELETE ALL",
                    addItems: "ADD ITEMS"
                },
                itemView: {
                    replacementCost: "Replacement cost",
                    quantity: "Quantity",
                    total: "Total"
                },
                checkbox: {
                    label: "Mark inventory as completed",
                    alertError: {
                        title: "Cannot be modified",
                        salesStageEnded: "Coverage is already purchased/canceled",
                        effectiveDateIsPast: "Effective date is past",
                        emptyInventory: "Your inventory is empty",
                        cannotUncheck: "You can not uncheck a marked inventory",
                    },
                    alertUnmark: {
                        title: "Your inventory has been unmarked",
                        description: "You can edit your inventory again"
                    }
                }
            }
        },
        modals: {
            declarationType: {
                title: "Confirm your declaration type",
                entireShipment: "Entire shipment",
                specificItems: "Specific Items",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "CONTINUE"
                },
                info: {
                    entireShipmentText: 'This option offers coverage if you are interested in insuring your entire shipment for Local, Intrastate, Interstate, and International moves. The total coverage amount is determined when you, the assured, place a lump sum value on all of your goods to be insured considering the declared value of the shipment equals no less than $8.00 (*)times the weight of the shipment. Any items individually valued at $1,000.00 (USD) (*) or more need to be listed separately in order to be covered at their specific replacement value.',
                    specificItemsText: 'This option offers coverage for specific items for Local, Intrastate, Interstate and International moves. For your benefit and for proper coverage, you must list and declare a replacement value on all of the items that you wish to insure. Items may be categorized as a group, such as "living room set, 7 pieces". However, please remember that in the event a claim is filed for any of the items in that category, the settlement amount may reflect the average value of the declared grouped items in that category.',
                    goBackButton: "Go Back"
                }
            },
            changePin: {
                title: "Change your PIN",
                fields: {
                    current: "CURRENT PIN",
                    new: "NEW PIN"
                },
                legend: "Must contain 4 numbers",
                error: "Wrong PIN. Retry",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "DONE"
                }
            },
            login: {
                title: "Enter your PIN",
                fields: {
                    pin: "PIN"
                },
                legend: "Must contain 4 numbers",
                buttons: {
                    resetPIN: "RESET PIN",
                    confirm: "DONE"
                },
                error: "Wrong PIN. Retry"
            },
            addPin: {
                title: "Create your PIN",
                fields: {
                    newPin: "NEW PIN"
                },
                legend: "Must contain 4 numbers",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "DONE"
                }
            },
            resetPin: {
                title: "PIN reset requested",
                description: "Your new PIN will be sent to your e-mail"
            },
            welcome: {
                setPin: {
                    title: "Would you like to set a PIN?",
                    description: "You can create a simple 4 digit pin to access your inventory.",
                    buttons: {
                        cancel: "Not now",
                        confirm: "Yes"
                    }
                },
                greetings: {
                    title: "Welcome to Customer Portal!",
                    body: `Over the next few pages, we'll ask you to identify the items you would like to insure.\n
                    Items are listed according to the category they fit best in. You must choose a category to list an item.`,
                    warning: "Items not listed on the valued inventory are not insured!",
                    disclaimer: "*Remember: Following items are excluded from the coverage service: Furs, Alcohol, etc",
                    buttons: {
                        confirm: "START"
                    }
                }
            },
            addEditItem: {
                addtitle: "Add new item",
                editTitle: "Edit item",
                formLabels: {
                    name: "Item name",
                    quantity: "Quantity",
                    category: "Category",
                    replacementCost: "Replacement cost per item",
                    description: "Description/conditions",
                    isArt: "Art",
                    isArtDescription: `Please check the Art box if the item is considered artwork.\nThis could be sculptures, paintings, antiques, statues, etc.`
                },
                buttons: {
                    cancel: "CANCEL",
                    confirm: "SAVE"
                },
                successfulAlert: {
                    title: "Successfull operation",
                    editDescription: "Your item was successfuly edited",
                    addDescription: "Your item was successfuly added"
                }
            },
            delete: {
                allItems: {
                    title: "Delete all items?",
                    description: "{quantity} items will be removed from the inventory. This process can not be undone."
                },
                singleItem: {
                    title: "Delete {name}?",
                    description: "{name}  will be removed from the inventory. This process can not be undone.",
                },
                buttons: {
                    cancel: "CANCEL",
                    confirm: "DELETE"
                },
                successfulAlert: {
                    title: "Successfull operation",
                    description: "Removed from inventory"
                }
            },
            inventoryCompleted: {
                title: "Have you finished the items upload?",
                description: "Your inventory will be marked as complete and you will not be able to modify it after that",
                disclaimer: "(This process can not be undone)",
                buttons: {
                    cancel: "CANCEL",
                    confirm: "ACCEPT"
                }
            },
            insuranceTerms: {
                linkText: {
                    text: "Terms and conditions apply. See summary of exclusions ",
                    link: "here"
                },
                title: "Insurance Coverage Terms and Conditions Summary",
                buttons: {
                    close: "CLOSE"
                }
            }
        },
        page404 : {
            title: "Nothing to see here!",
            goHome: "Go to the home page"
        },
        exceptionAlerts: {
            serverError: {
                title: "Oops! There has been an error",
                description: ""
            },
            tokenExpiredError: {
                title: "Oops! Auth has expired",
                description: ""
            }
        }
    }
};

