import { Container } from '@mui/material';
import React from 'react';
import { LoginModal } from '../components/modals/LoginModal';

export const Login = () => {
    return (
        <Container>
            <LoginModal />
        </Container>
    );
};
