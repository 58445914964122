import {AnyAction} from 'redux'
import { InventoryStatusType, SET_INVENTORY_STATUS } from './inventoryTypes';

export const inventoryInitialState = {
    inventoryStatus: true,
};

export function inventoryReducer(state = inventoryInitialState, action: AnyAction): InventoryStatusType {
    switch (action.type) {
    case SET_INVENTORY_STATUS:
        return {
            ...state,
            inventoryStatus: action.payload
        }
    default:
        return state;
    }
}
