import {AnyAction} from 'redux'
import { 
    CLOSE_CHPIN_MODAL,CLOSE_ADDPIN_MODAL, CLOSE_DEC_MODAL, CLOSE_INV_MODAL,
    OPEN_CHPIN_MODAL, OPEN_ADDPIN_MODAL, OPEN_DEC_MODAL, OPEN_INV_MODAL,
    ModalStatusType
} from './modalTypes';

    export const modalInitialState = {
    isDeclarationModalOpen: false,
    isChangePinModalOpen: false,
    isAddPinModalOpen: false,
    isInventoryCompletedModalOpen: false
};

export function modalReducer(state = modalInitialState, action: AnyAction): ModalStatusType {
    switch (action.type) {
    case OPEN_DEC_MODAL:
        return {
            ...state,
            isDeclarationModalOpen: true
        };
    case CLOSE_DEC_MODAL: 
        return {
            ...state,
            isDeclarationModalOpen: false
        };
    case OPEN_CHPIN_MODAL:
        return {
            ...state,
            isChangePinModalOpen: true
        };
    case CLOSE_CHPIN_MODAL:
        return {
            ...state,
            isChangePinModalOpen: false
        }
    case OPEN_ADDPIN_MODAL: 
        return {
            ...state,
            isAddPinModalOpen: true
        }
    case CLOSE_ADDPIN_MODAL: 
        return {
            ...state,
            isAddPinModalOpen: false
        }
    case OPEN_INV_MODAL: 
        return {
            ...state,
            isInventoryCompletedModalOpen: true
        }
    case CLOSE_INV_MODAL: 
        return {
            ...state,
            isInventoryCompletedModalOpen: false
        }
    default:
        return state;
    }
}
