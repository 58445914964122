import React, { useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './pages/Layout';
import { GeneralView } from './pages/GeneralView';
import { NoMatch } from './pages/NoMatch';
import { Landing } from './pages/Landing';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Login } from './pages/Login';
import { Coverage } from './types';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#003D7B',
            dark: '#1D242D',
        },
        secondary: {
            main: '#FFF',
            light: '#7AC142',
            dark: '#F26532',
        },
    },
    typography: {
        fontFamily: 'Open Sans',
        fontWeightLight: '400',
        fontWeightRegular: '500',
        fontWeightMedium: '600',
        fontWeightBold: '700',
        h2: {
            color: 'black',
        },
        h3: {
            color: '#003D7B',
            fontSize: '26px',
        },
        subtitle1: {
            color: 'black',
            fontSize: '20px',
        },
        h6: {
            color: 'grey',
            fontWeight: '700',
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#003D7B',
                    color: 'white',
                },
            },
        },
    },
});

const App = () => {
    const [coverage, setCoverage] = useState<Coverage | null>(null);
    
    return (
        <div className="App">
            <ThemeProvider theme={customTheme}>
                <Routes>
                    <Route path="/" element={<Layout coverage={coverage}/>}>
                        <Route index element={<GeneralView setCoverage={setCoverage} />} />
                        <Route path="landing" element={<Landing />} />
                        <Route path="login" element={<Login />} />
                    </Route>
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </ThemeProvider>
        </div>
    );
};

export default App;
