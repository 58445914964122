import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import { modifyPin } from '../../services/authService';
import { closeChangePinModal } from '../../store/modalActions';
import { useDispatch } from 'react-redux';
import { PinInput } from '../PinInput';
import { setPin } from '../../store/authActions';
import Lock from '../../Lock.svg';
import Box from '@mui/material/Box';
import { setSessionCookie } from '../../services/cookieService';

import strings  from '../../config/strings';
const { modals : { changePin } } = strings.UI;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
        img: {
            width: '100%',
        },
    };
});

const pinFieldInitialState = {
    pin: '',
    showPin: false,
};

const errorsInitialState = { newPinError: '', oldPinError: '' };

export const ChangePinModal = ({ isOpen }: { isOpen : boolean }) => {
    const classes = useStyles();
    const [oldPinField, setOldPinField] = useState(pinFieldInitialState);
    const [newPinField, setNewPinField] = useState(pinFieldInitialState);
    const [isModalDisabled, setIsModalDisabled] = useState(false);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(errorsInitialState);

    useEffect(() => {
        setOldPinField(pinFieldInitialState);
        setNewPinField(pinFieldInitialState);
        setErrors(errorsInitialState);
    }, [isOpen]);

    const validatePins = () => {
        let isValid = true;
        let newErrors = errorsInitialState;
        //The pin could be empty for the first time
        if (0 < oldPinField.pin.length && oldPinField.pin.length < 4) {
            newErrors = { ...newErrors, oldPinError: 'Must contain 4 numbers' };
            isValid = false;
        }
        if (newPinField.pin.length < 4) {
            newErrors = { ...newErrors, newPinError: 'Must contain 4 numbers' };
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handlePinSubmit = (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const token = localStorage.getItem('private_token');
        if (token) {
            if (validatePins()) {
                setIsModalDisabled(true);
                modifyPin(token, oldPinField.pin, newPinField.pin)
                    .then(() => {
                        setSessionCookie('pin', newPinField.pin);
                        dispatch(setPin(newPinField.pin));
                        dispatch(closeChangePinModal());
                        setIsModalDisabled(false);
                        setNewPinField(pinFieldInitialState);
                        setOldPinField(pinFieldInitialState);
                    })
                    .catch((err) => {
                        if (err.response.statusCode == 401) {
                            let newErrors = errorsInitialState;
                            newErrors = { ...newErrors, oldPinError: changePin.error };
                            setIsModalDisabled(false);
                            setErrors(newErrors);
                        } else {
                            console.log('[ERROR]', err);
                        }
                    });
            }
        }
    };

    const handleCancel = () => {
        dispatch(closeChangePinModal());
    }

    return (
        <CustomModal isOpen={isOpen} onClose={handleCancel}>
            <form>
            <Box display="flex" justifyContent="space-around">
                <Box
                    width="60px"
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    padding="10px"
                    borderRadius="50%"
                    bgcolor="#8EC1E3"
                    marginBottom="16px"
                >
                    <img src={Lock} className={classes.img} />
                </Box>
            </Box>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                {changePin.title}
            </Typography>
            <Typography align="center" component={'div'}>
                <PinInput
                    setValues={setOldPinField}
                    isModalDisabled={isModalDisabled}
                    values={oldPinField}
                    label={changePin.fields.current}
                    errorText={errors.oldPinError}
                    autofocus
                />
                <br />
                <PinInput
                    setValues={setNewPinField}
                    isModalDisabled={isModalDisabled}
                    values={newPinField}
                    label={changePin.fields.new}
                    errorText={errors.newPinError}
                />
                <br />
                <Typography marginTop="1px" align="left" fontSize="12px" variant="caption">
                    {changePin.legend}
                </Typography>
            </Typography>

            <Typography marginTop="40px" align="right">
                <Button
                    disabled={isModalDisabled}
                    sx={{ marginRight: '16px' }}
                    className={classes.button}
                    color="primary"
                    variant="text"
                    onClick={handleCancel}
                >
                    {changePin.buttons.cancel}
                </Button>
                <Button
                    type="submit"
                    disabled={isModalDisabled}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handlePinSubmit}
                >
                    {changePin.buttons.confirm}
                </Button>
            </Typography>
            </form>
        </CustomModal>
    );
};
