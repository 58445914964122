import {AnyAction} from 'redux'
import { AuthStatusType, SET_DECLARATION_TYPE, SET_PIN, SET_PROGRAM_NAME, SET_READ_ONLY } from './authTypes';

export const authInitialState = {
    pin: null,
    programName: null,
    declarationType: null,
    readOnly: true,
};

export function authReducer(state = authInitialState, action: AnyAction): AuthStatusType {
    switch (action.type) {
    case SET_PIN:
        return {
            ...state,
            pin: action.payload
        };
    case SET_PROGRAM_NAME:
        return {
            ...state,
            programName: action.payload
        }
    case SET_DECLARATION_TYPE:
        return {
            ...state,
            declarationType: action.payload
        }
    case SET_READ_ONLY:
        return {
            ...state,
            readOnly: action.payload
        }
    default:
        return state;
    }
}
