import React from 'react';
import { CircularProgress } from '@mui/material';
import Logo from '../RIG_logo.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
    return {
        logo: {
            width: '100%',
        },
        div: {
            marginTop: '10px',
            textAlign: 'center'
        },
        root: {
            maxWidth: 240,
            margin: 'auto',
        },
    };
});

export const Loading = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src={Logo} className={classes.logo} />
            <div className={classes.div}>
                <CircularProgress />
            </div>
        </div>
    );
};
