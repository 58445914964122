import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import strings from '../config/strings'
import { TermsProps } from '../types'

export const Terms = ({ setShowInsuranceTermsModal }: TermsProps) => {
  return (
    <Box sx={{ mt: 2 }}>
        <Typography variant="body2">
        {strings.UI.modals.insuranceTerms.linkText.text}
            <Link onClick={() => setShowInsuranceTermsModal(true)}>
            {strings.UI.modals.insuranceTerms.linkText.link}
            </Link>
        </Typography>
    </Box>
  )
}
