import React from 'react';
import { ButtonProps, Button, CircularProgress } from '@mui/material';

interface Props extends ButtonProps {
  loading: boolean;
  children: string;
}

export const LoadingButton : React.FC<Props> = ({ loading, onClick, ...buttonProps }) => {

  if (loading) {
    return (
      <Button {...buttonProps} disabled={true}>
        <CircularProgress 
            color='primary'
            size='60%'
        />
      </Button>
    );
  } else {
    return (
      <Button onClick={onClick} {...buttonProps} />
    );
  }
}
