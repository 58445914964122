import React from 'react';
import { Button, Typography } from '@mui/material';
import { CustomModal } from './CustomModal';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { openDeclarationModal } from '../../store/modalActions';

import strings  from '../../config/strings';
const { modals : { declarationType : { info } } } = strings.UI;
const specificItemsBody = info.specificItemsText;

const useStyles = makeStyles(() => {
    return {
        disclamer: {
            marginTop: '40px',
        },
        button: {
            margin: '40px',
        },
    };
});

interface Props {
    isOpen: boolean;
    setShowSpecificItemsInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SpecificItemInfoModal = ({ isOpen, setShowSpecificItemsInfoModal }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const handleCancel = () => {
        dispatch(openDeclarationModal());
        setShowSpecificItemsInfoModal(false);
    }
    
    return (
        <CustomModal isOpen={isOpen} onClose={handleCancel}>
            <Typography
                marginBottom="20px"
                align="center"
                fontWeight="bold"
                id="modal-modal-title"
                variant="h3"
                component="h2"
            >
                Specific items
            </Typography>
            <Typography
                lineHeight="26px"
                align="center"
                fontWeight="normal"
                variant="caption"
                component="h2"
                sx={{ whiteSpace: 'pre-line', color: '#5A6A7F', fontSize: '20px' }}
            >
                {specificItemsBody}
            </Typography>
            <Typography marginTop="40px" align="center">
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                >
                    {info.goBackButton}
                </Button>
            </Typography>
        </CustomModal>
    );
};
